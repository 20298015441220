* {
    margin: 0;
    padding: 0;
}

.navbar {
    background-color: rgb(97, 97, 226);
}

.navbar a {
    color: white;
}

.hero-section {
    width: 100%;
    height: 500px;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/hero1.jpg"); */
    background-image: url("../assets/hero2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* position: relative; */
}

@media only screen and (min-width: 751px) {
    .hero-section {
        width: 100%;
        height: 500px;
        background-image: url("../assets/hero4.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media only screen and (max-width: 400px) {
    .hero-section {
        width: 100%;
        height: 200px;
        background-image: url("../assets/hero_small.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media only screen and (min-width: 401px) and (max-width: 750px) {
    .hero-section {
        width: 100%;
        height: 300px;
        background-image: url("../assets/hero_small.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

/* .hero-box {
    position: relative;
    height: 250px;
    max-width: 600px;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-image: linear-gradient(rgba(255,255,255,0.2), rgba(255,255,255,0.2));
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
} */

.hero-title {
    font-size: 2em;
}

.hero-subtitle {
    font-size: 1em;
}

.contact {
    font-size: 1.9vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.contact * {
    flex-grow: 1;
    margin: auto;
}

.hero-cta {
    position: relative;
    left: 50%;
    top: 180px;
    transform: translate(-50%, -50%);
}

.description-box {
    margin: 15px auto;
    background-color: white;
    text-align: center;
}

.storages-box {
    background-color: rgb(240, 240, 255);
    display: flex;
    flex-direction: row;
}

.storages-box li {
    font-size: 120%;
}

.i-box {
    padding: 25px 10%;
}

.i-box-half {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    margin: 0 3px;
}

.map {
    height: 400px;
    width: 100%;
}

#sticky-footer {
    flex-shrink: none;
    background-color: rgb(240, 240, 255);
}

.perm {
    display: block;
    position: fixed;
    width: 100vw;
    height: 35px;
    background-color: rgb(97, 97, 226);
    color: white;
    bottom: 0;
    left: 0;
    font-size: 80%;
}

#acceptCookieButton {
    width: 50px;
}

@media only screen and (max-width: 400px) {
    .perm {
        display: block;
        position: fixed;
        width: 100vw;
        height: 50px;
        background-color: rgb(97, 97, 226);
        color: white;
        bottom: 0;
        left: 0;
    }
}